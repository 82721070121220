import React from "react";
import config from "config.js"
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Divider from "@material-ui/core/Divider";
import Paper from '@material-ui/core/Paper';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import moment from "moment";
import JSONPretty from 'react-json-pretty';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Button } from "@material-ui/core";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { IconButton } from "@material-ui/core";
import { width } from "@mui/system";
import LogsCollapse from './LogCollapse';

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    overflowX: "scroll"
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,

    };
}
const useStyles = makeStyles((theme) => ({

    root: {
        backgroundColor: theme.palette.background.paper,
        width: "1500px",
    },
    tabContent: {
        maxHeight: "60vh",
        overflowX: "scroll"

    },
    logPaper: {
        padding: "15px",

    },




}));

export default function FullWidthTabs(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const [logData, setLogData] = React.useState([]);
    const [netSysLog, setNetSysLog] = React.useState([]);
    const [newNetSysLog, setNewNetSysLog] = React.useState([]);
    const [avenuesSysLog, setAvenuesLog] = React.useState([]);
    const [newAvenuesSysLog, setNewAvenuesLog] = React.useState([]);
    const [signupLog, setSignupLog] = React.useState([]);
    const [onboardingLog, setOnboardingLog] = React.useState([]);
    const [sadadLog, setSadadLog] = React.useState([]);
    const [tabiibLog, setTabiibLog] = React.useState([]);
    const [multiCurrencyLog, setMultiCurrencyLog] = React.useState([]);
    const [pieLogs, setPieLogs] = React.useState([]);
    const [flyLogs, setFLYLogs] = React.useState([])
    const [ibanLog, setIbanLog] = React.useState([]);

    // const [mdchealthlogs, setMdcHealthLogs] = React.useState([])
    const [noLogData, setNoLogData] = React.useState(false);
    const [noNetSysLog, setNoNetSysLog] = React.useState(false);
    const [noNewNetSysLog, setNoNewNetSysLog] = React.useState(false);
    const [noAvenuesSysLog, setNoAvenuesLog] = React.useState(false);
    const [noNewAvenuesSysLog, setNoNewAvenuesLog] = React.useState(false);
    const [noSignupLog, setNoSignupLog] = React.useState(false);
    const [noOnboardingLog, setNoOnboardingLog] = React.useState(false);
    const [noSadadLog, setNoSadadLog] = React.useState(false);
    const [noTabiibLog, setNoTabiibLog] = React.useState([]);
    const [noMultiLog, setNoMultiLog] = React.useState(false);
    const [noPieLogs, setNoPieLogs] = React.useState(false);
    const [noFlyLogs, setNoFlyLogs] = React.useState(false);
    // const [noMdcLogs, setNoMdcLogs] = React.useState(false);

    const [netSysPageNo, setNetSysPageNo] = React.useState(0);
    const [sadadPageNo, setSadadPageNo] = React.useState(0);
    const [showLoadMore, setShowLoadMore] = React.useState(true);
    const [noIbanLog, setNoIbanLog] = React.useState(false);


    const logRef = React.useRef(null);


    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log(newValue)
        getLogs(newValue, props.userID)
    };

    const handleChangeIndex = (index) => {
        setValue(index);

    };
    const copyToClipboard = (e) => {

        navigator.clipboard.writeText(JSON.stringify({

            url: e.url_called,

            request: e.request,

            response: e.response

        }));

    };
    function parseNestedJSONString(jsonString) {

        try {

            const parseNested = (str) => {

                let result;

                try {

                    result = JSON.parse(str);

                } catch (error) {

                    if (typeof str === 'string' && str.startsWith('"') && str.endsWith('"')) {

                        return parseNested(str.slice(1, -1));

                    } else {

                        return str;

                    }

                }



                if (typeof result === 'string') {

                    return parseNested(result);

                }



                if (typeof result === 'object') {

                    for (let key in result) {

                        result[key] = parseNested(result[key]);

                    }

                }



                return result;

            };



            return parseNested(jsonString);

        } catch (error) {

            console.error('Error parsing JSON string:', error);

            return null;

        }

    }
    const copyToClipboardNet = (e) => {
        navigator.clipboard.writeText(JSON.stringify({
            url: e.url,
            request: e.request,
            response: e.response
        }));
    };


    const copyToClipboardNew = (e) => {

        navigator.clipboard.writeText(JSON.stringify({

            url: e.url_called ? e.url_called : e.url ? e.url : "",

            request: parseNestedJSONString(e.request),

            response: parseNestedJSONString(e.response)

        }, null, 4));

    };


    React.useEffect(() => {
        if (props.userID !== null) {
            getLogs(0, props.userID)
            setValue(0)
            setLogData([]);
            setNetSysLog([]);
            setNewNetSysLog([]);
            setAvenuesLog([]);
            setNewAvenuesLog([]);
            setSignupLog([]);
            setOnboardingLog([])
            setMultiCurrencyLog([])
            setPieLogs([])
            setIbanLog([]);

            setFLYLogs([])
            setNoLogData(false);
            setNoNetSysLog(false);
            setNoNewNetSysLog(false);
            setNoIbanLog(false);

            setNoAvenuesLog(false);
            setNoNewAvenuesLog(false);
            setNoSignupLog(false);
            setNoOnboardingLog(false)
            setTabiibLog([]);
            setNoTabiibLog(false);
            setNoFlyLogs(false)
            setNoMultiLog(false);
            setNoPieLogs(false);
            // setMdcHealthLogs([])
            // setNoMdcLogs(false);
        } else {
            setLogData([]);
            setNetSysLog([]);
            setNewNetSysLog([]);
            setIbanLog([]);

            setAvenuesLog([]);
            setNewAvenuesLog([]);
            setSignupLog([]);
            setOnboardingLog([])
            setFLYLogs([])
            setNoLogData(false);
            setNoNetSysLog(false);
            setNoNewNetSysLog(false);
            setNoIbanLog(false);

            setNoAvenuesLog(false);
            setNoNewAvenuesLog(false);
            setNoSignupLog(false);
            setNoOnboardingLog(false)
            setTabiibLog([]);
            setNoTabiibLog(false);
            setNoMultiLog(false);
            setNoPieLogs(false)
            setMultiCurrencyLog([])
            setPieLogs([]);
            setNoFlyLogs(false)
            // setNoMdcLogs(false)
            // setMdcHealthLogs([]);
        }

    }, [props.userID]);

    const fetchLog = (id, page) => {
        //function to fetch log of a customer
        if ((id && page) || page == 0) {
            axios.get(`${config.url().baseURL}/user-log/${id}/${page}`).then((resp) => {
                let respData = resp.data;
                setNetSysPageNo(page + 1)
                if (!respData.err) {
                    if (page == 0) {
                        setLogData(respData.data);
                    } else {
                        setLogData(p => [...p, ...respData.data])
                    }
                } else {
                    if (page == 0) {
                        setNoLogData(true)
                    } else {
                        setShowLoadMore(false)
                    }
                }
            });
        }
    };


    const getNewNetSysLogs = (id, page) => {
        //function to fetch netsys logs for a customer
        if ((id && page) || page == 0) {
            axios.get(`${config.url().baseURL}/get-netsys-log-new/${id}/${page}`)
                .then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        setNetSysPageNo(page + 1)
                        if (page == 0) {
                            setNewNetSysLog(respData.data)
                        } else {
                            setNewNetSysLog(p => [...p, ...respData.data])
                        }

                    } else {
                        if (page == 0) {
                            setNoNewNetSysLog(true)
                        } else {
                            setShowLoadMore(false)
                        }
                    }
                })
                .catch((err) => {
                    setNewNetSysLog([]);
                    setNoNewNetSysLog(true)
                });
        }
    };


    const getIbanLogs = async (userID, page = 1, limit = 10) => {
        
        if (userID && (page >= 0)) {  // Ensure userID is valid and page is a valid number
            try {
    
                const response = await axios.post(`${config.url().baseURL}/iban/logs`, {
                    userID,
                    page,
                    limit
                });
    
                const { data: respData } = response;
    
                if (!respData.err) {
                    // Update the next page number for infinite scrolling or pagination
                    setNetSysPageNo(page + 1);
                    if((respData?.data[0]?.logs).length<1){
                        setShowLoadMore(false)
                        
                    }

                    if((respData?.data[0]?.logs).length<1&&page!=0){
                        setShowLoadMore(false)
                        setNoIbanLog(true)
                    }
                    if (page === 0) {
                        // For the first page, replace the current logs
                        setIbanLog(respData?.data[0]?.logs);
                    } else {
                        // For subsequent pages, append new logs to the existing ones
                        setIbanLog(prevLogs => [...prevLogs, ...respData.data[0]?.logs]);
                    }
    
                } else {
                    // Handle when there's an error response
                    if (page === 0) {
                        setNoIbanLog(true);  // No logs found on the first page
                    } else {
                        setShowLoadMore(false);  // Stop load more functionality
                        setNoIbanLog(false)
                    }
                }
            } catch (error) {
                console.error("Error fetching IBAN logs:", error);
                setIbanLog([]);  
                setNoIbanLog(true);  
            } 
        }
    };
    



    const getNewAvenuesLogs = (id, page) => {
        //function to fetch netsys logs for a customer
        if ((id && page) || page == 0) {
            axios.get(`${config.url().baseURL}/get-avenues-log-new/${id}/${page}`)
                .then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        setNetSysPageNo(page + 1)
                        if (page == 0) {
                            setNewAvenuesLog(respData.data)
                        } else {
                            setNewAvenuesLog(p => [...p, ...respData.data])
                        }
                    } else {
                        if (page == 0) {
                            setNoNewAvenuesLog(true)
                        } else {
                            setShowLoadMore(false)
                        }

                    }
                })
                .catch((err) => {
                    setNewAvenuesLog([]);
                    setNoNewAvenuesLog(true)
                });
        }
    }
    const getSignupLogs = (id) => {
        //function to fetch signup logs for a customer
        if (signupLog.length == 0) {
            axios.get(`${config.url().baseURL}/get-signup-log/${id}`)
                .then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        setSignupLog(respData.data)
                    } else {

                        setSignupLog([]);
                        setNoSignupLog(true)
                    }
                })
                .catch((err) => {
                    setSignupLog([]);
                    setNoSignupLog(true)
                });
        }
    }
    const getOnboardingLogs = (id) => {
        //function to fetch Onboarding logs for a customer
        if (onboardingLog.length == 0) {
            axios.get(`${config.url().baseURL}/get-onboarding-logs/${id}`)
                .then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        setOnboardingLog(respData.data)
                    } else {
                        setOnboardingLog([]);
                        setNoOnboardingLog(true)
                    }
                })
                .catch((err) => {
                    setOnboardingLog([]);
                    setNoOnboardingLog(true)
                });
        }
    }

    const getSadadLogs = (id, page) => {
        //function to fetch netsys logs for a customer
        if ((id && page) || page == 0) {
            axios.get(`${config.url().baseURL}/get-sadadLogs/${id}/${page}`)
                .then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        setSadadPageNo(page + 1)
                        if (page == 0) {
                            setSadadLog(respData.data)
                        } else {
                            setSadadLog(p => [...p, ...respData.data])
                        }

                    } else {
                        if (page == 0) {
                            setNoSadadLog(true)
                        } else {
                            setShowLoadMore(false)
                        }
                    }
                })
                .catch((err) => {
                    setSadadLog([]);
                    setNoSadadLog(true)
                });
        }
    };
    const getTabiibcardLogs = (id, page) => {
        //function to fetch Tabiib log of a customer
        if ((id && page) || page == 0) {
            axios.get(`${config.url().baseURL}/get-tabib-logs/${id}/${page}`).then((resp) => {
                let respData = resp.data;
                setNetSysPageNo(page + 1)
                if (!respData.err) {
                    if (page == 0) {
                        setTabiibLog(respData.data);
                    } else {
                        setTabiibLog(p => [...p, ...respData.data])
                    }
                } else {
                    if (page == 0) {
                        setNoTabiibLog(true)
                    } else {
                        setShowLoadMore(false)
                    }
                }
            })
                .catch((err) => {
                    setTabiibLog([])
                    setNoTabiibLog(true);
                })
        }
    };
    const getPieLogs = (userid, pageno) => {
        //function to get Tabiib log of a customer
        if ((userid && pageno) || pageno == 0) {
            axios.get(`${config.url().baseURL}/get-pieLogs/${userid}/${pageno}`).then((resp) => {
                let respData = resp.data;
                setNetSysPageNo(pageno + 1)
                if (!respData.err) {
                    if (pageno == 0) {
                        setPieLogs(respData.data);
                    } else {
                        setPieLogs(p => [...p, ...respData.data])
                    }
                } else {
                    if (pageno == 0) {
                        setNoPieLogs(true)
                    }
                    else {
                        setShowLoadMore(false)
                    }
                }

            })
                .catch((err) => {
                    setPieLogs([])
                    setNoPieLogs(true)
                })
        }
    }
    const getFLYLogs = (userid, pageno) => {
        //function to get Tabiib log of a customer
        if ((userid && pageno) || pageno == 0) {
            axios.get(`${config.url().baseURL}/get-flyy-logs/${userid}/${pageno}`).then((resp) => {
                let respData = resp.data;
                setNetSysPageNo(pageno + 1)
                if (!respData.err) {
                    if (pageno == 0) {
                        setFLYLogs(respData.data);
                    } else {
                        setFLYLogs(p => [...p, ...respData.data])
                    }
                } else {
                    if (pageno == 0) {
                        setNoFlyLogs(true)
                    }
                    else {
                        setShowLoadMore(false)
                    }
                }

            })
                .catch((err) => {
                    setFLYLogs([])
                    setNoFlyLogs(true)
                })
        }
    }

    const getmultiCurrencylogs = (userid, pageno) => {
        if (userid && pageno || pageno == 0) {
            axios.get(`${config.url().baseURL}/get-multicurrency-log/${userid}/${pageno}`)
                .then((resp) => {
                    let respData = resp.data;
                    setNetSysPageNo(pageno + 1)
                    if (!respData.err) {
                        if (pageno == 0) {
                            setMultiCurrencyLog(respData.data)
                        }
                        else {
                            setMultiCurrencyLog(p => [...p, ...respData.data])
                        }
                    } else {
                        if (pageno == 0) {
                            setNoMultiLog(true)
                        } else {
                            setShowLoadMore(false)
                        }
                    }
                })
                .catch((err) => {
                    setMultiCurrencyLog([])
                    setNoMultiLog(true)
                })
        }
    }




    const getLogs = (id, userID) => {
        //function to get logs
        setNetSysPageNo(0)
        setShowLoadMore(true)
        switch (id) {
            case 0:
                fetchLog(userID, 0)
                break;
            case 1:
                getNewNetSysLogs(userID, 0)
                break;
            case 2:
                getNewAvenuesLogs(userID, 0)
                break;
            case 3:
                getSadadLogs(userID, 0)
                break;
            case 4:
                getTabiibcardLogs(userID, 0);
                break;
            case 5:
                getmultiCurrencylogs(userID, 0);
                break;
            case 6:
                getPieLogs(userID, 0);
                break;
            case 7: getFLYLogs(userID, 0)
                break;
            // case 8: 
            //     getmdchealthlogs(userID, 0)
            //     break;
            case 8: getOnboardingLogs(userID)
                break;

            case 9: getIbanLogs(userID)
                break;

            case 10: getSignupLogs(userID)
                break;

            default:
                break;
        }
    }


    return (
        <div className={classes.companyLogModal}>
            <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none', display: 'block' }}>
                <div style={{ overflowX: 'auto' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        aria-label="full width tabs example"
                    >
                        <Tab label="Logs" {...a11yProps(0)} />
                        <Tab label="NetSys" {...a11yProps(0)} />
                        <Tab label="Avenues" {...a11yProps(1)} />
                        <Tab label="Sadad" {...a11yProps(3)} />
                        <Tab label="Tabiib" {...a11yProps(0)} />
                        <Tab label="MultiCurrency" {...a11yProps(0)} />
                        <Tab label="PIE Logs" {...a11yProps(0)} />
                        <Tab label="FLYY Logs" {...a11yProps(1)} />
                        {/* <Tab label="MDC Logs" {...a11yProps(1)} /> */}
                        <Tab label="Onboarding" {...a11yProps(1)} />
                        <Tab label="Iban Logs" {...a11yProps(1)} />


                        {
                            props.userType == "Shufti Initiated Signup" || props.userType == "Initiate" ? (
                                <Tab label="Sign up " {...a11yProps(1)} />
                            ) : null
                        }
                    </Tabs>
                </div>

            </AppBar>
            <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                className={classes.tabContent}
                overflowX="scroll"
                onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Collapse
                        in={noLogData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noLogData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={logData.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={logData.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer} ref={logRef} id="log-data-id">
                                {logData.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log.logID}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon s />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.date ? (
                                                    <>
                                                        Date : {moment(log.date).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time : {moment(log.date).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.Type ? (
                                                    <>
                                                        Type : {log.Type}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.url_called ? (
                                                    <>
                                                        URL : {log.url_called}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => fetchLog(props.userID, netSysPageNo)} >Load More</Button> : null
                                }
                            </div>

                        </Collapse>
                    </Collapse>
                </TabPanel>

                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Collapse
                        in={noNewNetSysLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    {/* <LogsCollapse
                        logs={newNetSysLog}
                        showLoadMore={showLoadMore}
                        getLogs={getNewNetSysLogs}
                        userID={props.userID}
                        pageNo={netSysPageNo}
                        classes={classes}
                    >

                    </LogsCollapse> */}
                    <Collapse
                        in={!noNewNetSysLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={newNetSysLog.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={newNetSysLog.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>

                            <div className={classes.logContainer}>

                                {newNetSysLog.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log._id}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon s />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.name ? (
                                                    <>
                                                        Log Type :{log.name}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeStamp ? (
                                                    <>
                                                        Date : {moment(log.timeStamp).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time : {moment(log.timeStamp).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}

                                                {log.url ? (
                                                    <>
                                                        URL : {log.url}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeRequired ? (
                                                    <>
                                                        Duration : {log.timeRequired}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => getNewNetSysLogs(props.userID, netSysPageNo)} >Load More</Button> : null
                                }

                            </div>
                        </Collapse>
                    </Collapse>
                </TabPanel>

                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Collapse
                        in={noNewAvenuesSysLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noNewAvenuesSysLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={newAvenuesSysLog.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={newAvenuesSysLog.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>

                            <div className={classes.logContainer}>

                                {newAvenuesSysLog.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log._id}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon s />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.name ? (
                                                    <>
                                                        Log Type :{log.name}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeStamp ? (
                                                    <>
                                                        Date : {moment(moment(new Date(log.timeStamp))).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time :  {moment(moment(new Date(log.timeStamp))).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}

                                                {log.url ? (
                                                    <>
                                                        URL : {log.url}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeRequired ? (
                                                    <>
                                                        Duration : {log.timeRequired}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => getNewAvenuesLogs(props.userID, netSysPageNo)} >Load More</Button> : null
                                }
                            </div>
                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <Collapse
                        in={noSadadLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noSadadLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>

                        <Collapse
                            in={sadadLog.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={sadadLog.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer}>
                                {sadadLog.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log._id}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon s />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.name ? (
                                                    <>
                                                        Log Type :{log.name}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeStamp ? (
                                                    <>
                                                        Date : {moment(moment(new Date(log.timeStamp))).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time :  {moment(moment(new Date(log.timeStamp))).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}

                                                {log.url_called ? (
                                                    <>
                                                        URL : {log.url_called}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeRequired ? (
                                                    <>
                                                        Duration : {log.timeRequired}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />

                                        </>

                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => getSadadLogs(props.userID, sadadPageNo)} >Load More</Button> : null
                                }
                            </div>
                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <Collapse
                        in={noTabiibLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noTabiibLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={tabiibLog.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={tabiibLog.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer}>
                                {tabiibLog.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log._id}                // axios.post(`${config.url().baseURL}/get-p2p-transactions`, data)

                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeStamp ? (
                                                    <>
                                                        Date : {moment(log.timeStamp).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time : {moment(log.timeStamp).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {/* {log.Type ? (
                                                    <>
                                                        Type : {log.Type}
                                                        <br />
                                                    </>
                                                ) : null} */}
                                                {log.url ? (
                                                    <>
                                                        URL : {log.url}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => getTabiibcardLogs(props.userID, netSysPageNo)} >Load More</Button> : null
                                }
                            </div>

                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={5} dir={theme.direction}>
                    <Collapse
                        in={noMultiLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noMultiLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={multiCurrencyLog.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={multiCurrencyLog.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer}>
                                {multiCurrencyLog.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log._id}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.name ? (
                                                    <>
                                                        Log Type : {log.name}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeStamp ? (
                                                    <>
                                                        Date : {moment(log.timeStamp).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time : {moment(log.timeStamp).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.url ? (
                                                    <>
                                                        URL : {log.url}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeRequired ? (
                                                    <>
                                                        Duration : {log.timeRequired}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => getmultiCurrencylogs(props.userID, netSysPageNo)} >Load More</Button> : null
                                }
                            </div>

                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={6} dir={theme.direction}>
                    <Collapse
                        in={noPieLogs}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noPieLogs}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={pieLogs.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={pieLogs.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer}>
                                {pieLogs.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log._id}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.name ? (
                                                    <>
                                                        Log Type : {log.name}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.createdAt ? (
                                                    <>
                                                        Date : {moment(log.createdAt).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time : {moment(log.createdAt).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.url ? (
                                                    <>
                                                        URL : {log.url}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeRequired ? (
                                                    <>
                                                        Duration : {log.timeRequired}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => getPieLogs(props.userID, netSysPageNo)} >Load More</Button> : null
                                }
                            </div>

                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={7} dir={theme.direction}>
                    <Collapse
                        in={noFlyLogs}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noFlyLogs}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={flyLogs.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={flyLogs.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer}>
                                {flyLogs.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log._id}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.name ? (
                                                    <>
                                                        Log Type : {log.name}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeStamp ? (
                                                    <>
                                                        Date : {moment(log.timeStamp).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time : {moment(log.timeStamp).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.url ? (
                                                    <>
                                                        URL : {log.url}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeRequired ? (
                                                    <>
                                                        Duration : {log.timeRequired}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => getFLYLogs(props.userID, netSysPageNo)} >Load More</Button> : null
                                }
                            </div>

                        </Collapse>
                    </Collapse>
                </TabPanel>
                {/* --------------------------- */}
                {/* <TabPanel value={value} index={6} dir={theme.direction}>
                    <Collapse
                        in={noMdcLogs}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noMdcLogs}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={mdchealthlogs.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={mdchealthlogs.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer}>
                                {mdchealthlogs.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log._id}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboard(log) }}>
                                                            <FileCopyIcon />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.name ? (
                                                    <>
                                                        Log Type : {log.name}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.createdAt ? (
                                                    <>
                                                        Date : {moment(log.createdAt).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time : {moment(log.createdAt).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.url ? (
                                                    <>
                                                        URL : {log.url}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeRequired ? (
                                                    <>
                                                        Duration : {log.timeRequired}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => getmdchealthlogs (props.userID, netSysPageNo)} >Load More</Button> : null
                                }
                            </div>

                        </Collapse>
                    </Collapse>
                </TabPanel> */}
                <TabPanel value={value} index={8} dir={theme.direction}>
                    <Collapse
                        in={noOnboardingLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noOnboardingLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={onboardingLog.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={onboardingLog.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer}>
                                {onboardingLog.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log._id}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon s />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeStamp ? (
                                                    <>
                                                        Date : {moment(log.timeStamp).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time : {moment(log.timeStamp).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.name ? (
                                                    <>
                                                        Log Type : {log.name}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.url ? (
                                                    <>
                                                        URL : {log.url}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.timeRequired ? (
                                                    <>
                                                        Duration : {log.timeRequired}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => getOnboardingLogs(props.userID, netSysPageNo)} >Load More</Button> : null
                                }
                            </div>

                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={9} dir={theme.direction}>
                    <Collapse
                        in={noIbanLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <LogsCollapse
                        logs={ibanLog}
                        showLoadMore={showLoadMore}
                        getLogs={getIbanLogs}
                        userID={props.userID}
                        pageNo={netSysPageNo}
                        classes={classes}
                    >

                    </LogsCollapse>
                    </TabPanel>

                <TabPanel value={value} index={10} dir={theme.direction}>
                    <Collapse
                        in={noSignupLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noSignupLog}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>

                        <Collapse
                            in={signupLog.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={signupLog.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer}>
                                {signupLog.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log._id}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.reference ? (
                                                    <>
                                                        Reference :{log.reference}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.start_time ? (
                                                    <>
                                                        Date : {moment(moment(new Date(log.start_time)).utcOffset(180)).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time :  {moment(moment(new Date(log.start_time)).utcOffset(180)).format("HH:MM:SS")}
                                                        <br />
                                                    </>
                                                ) : null}

                                                {log.response_IGALogin ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>IGA Login Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response_IGALogin} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.response_IGAUpload ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>IGA Upload Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response_IGAUpload} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.response_IGARecord ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>IGA Record Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response_IGARecord} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.response_IGAGetKYC ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>IGA KYC Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={JSON.parse(JSON.stringify(log.response_IGAGetKYC))} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.response_IGAPublish ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response IGA Publish</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={JSON.parse(JSON.stringify(log.response_IGAPublish))} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                            </div>
                        </Collapse>
                    </Collapse>
                </TabPanel>

            </SwipeableViews>
        </div >
    );
}
