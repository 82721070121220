import { func } from "prop-types"

const config =
{
    url: () => {
        let host = window.location.host
        switch (host) {
            case "cxportal.bfcpay.com":
                return (
                    {
                        baseURL: "https://mobile.bfcpay.com/api",
                        appURL: "https://mobile.bfcpay.com/app",
                        app_URL: "https://mobile.bfcpay.com/app",
                        transactionMSURL: 'https://mobile.bfcpay.com/payments',
                        wallet_URL: 'https://mobile.bfcpay.com/wallet',
                    }
                )
            case "dk0xcmg6xdtcf.cloudfront.net":
                return (
                    {
                        baseURL: "https://mobile.bfcpay.com/api",
                        appURL: "https://mobile.bfcpay.com/app",
                        app_URL: "https://mobile.bfcpay.com/app",
                        transactionMSURL: 'https://mobile.bfcpay.com/payments',
                        wallet_URL: 'https://mobile.bfcpay.com/wallet',

                    }
                )
            case "bfcp-cxportal-alb-uat.bfcpayments.com":
                return (
                    {
                        baseURL: "https://bfcp-mob-alb-uat.bfcpayments.com/api",
                        appURL: "https://bfcp-mob-alb-uat.bfcpayments.com/app",
                        app_URL: "https://bfcp-mob-alb-uat.bfcpayments.com/app",
                        transactionMSURL: 'https://bfcp-mob-alb-uat.bfcpayments.com/payments',
                        wallet_URL: 'https://bfcp-mob-alb-uat.bfcpayments.com/wallet',
                    }
                )
            case "bfcp-mob-alb-sand.bfcpayments.com":
                return (
                    {
                        baseURL: "http://bfcp-mob-alb-sand.bfcpayments.com/api",
                        app_URL: 'http://bfcp-mob-alb-sand.bfcpayments.com/bfcApp/api',
                        appURL: "http://bfcp-mob-alb-sand.bfcpayments.com/bfcApp/app",
                        transactionMSURL: 'http://bfcp-mob-alb-sand.bfcpayments.com/payments',
                        wallet_URL: 'https://bfcp-mob-alb-uat.bfcpayments.com/wallet',
                        host: host
                    }
                )
            case "localhost:3000":
                return (

                    {

                        // baseURL: "http://0.0.0.0:3001/api",

                        // baseURL: "https://mobile.bfcpay.com/api",

                        // baseURL: "https://bfcp-mob-alb-uat.bfcpayments.com/api",
                        // baseURL: "http://553e-103-142-31-136.in.ngrok.io/api",

                        appURL: "http://bfcp-mob-alb-sand.bfcpayments.com/bfcApp/app",

                        // baseURL1:'http://bfcp-mob-alb-sand.bfcpayments.com/bfcApp/app',

                        

                        baseURL: "http://bfcp-mob-alb-sand.bfcpayments.com/api",

                    //    appURL: 'http://bfcp-mob-alb-sand.bfcpayments.com/bfcApp/api',

                        // UAT 
                        // appURL: 'https://bfcp-mob-alb-uat.bfcpayments.com/app',
                        app_URL: 'https://bfcp-mob-alb-uat.bfcpayments.com/app',
                        transactionMSURL: 'https://bfcp-mob-alb-uat.bfcpayments.com/payments',
                        wallet_URL: 'https://bfcp-mob-alb-uat.bfcpayments.com/wallet',
                        host: host,
                        // UAT 
                        // baseURL: "https://bfcp-mob-alb-uat.bfcpayments.com/api",
                        // appURL: "https://bfcp-mob-alb-uat.bfcpayments.com/app",
                        // app_URL: "https://bfcp-mob-alb-uat.bfcpayments.com/app",
                        // transactionMSURL: 'https://bfcp-mob-alb-uat.bfcpayments.com/payments',
                        // wallet_URL: 'https://bfcp-mob-alb-uat.bfcpayments.com/wallet',

                    }

                )
            default:
                return (
                    {
                        baseURL: "http://bfcp-mob-alb-sand.bfcpayments.com/api",
                        appURL: 'http://localhost:3000/app',
                        app_URL: 'http://localhost:3000/app',
                        wallet_URL: 'https://bfcp-mob-alb-uat.bfcpayments.com/wallet',
                        transactionMSURL: ''
                    }
                )
        }
    },
}

export default config

